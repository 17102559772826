import { createMultiPartFormBody } from "../util/Util";
import { ApiKey, ApiUrl, SessionKey, AlertTypes, CommonStatus } from "../util/Constant";
import { showResponseMessage, showMessage } from "./AppAction";
import ApiEngine from '../util/ApiEngine.js';
import { initFirebaseDatabase } from "./FirebaseAction";
import i18n from '../localization/i18n';

export const _LOGIN_SUCCESS = 'LoginSuccess';
export const _LOGIN_FAILED = 'LoginFailed';
export const _LOGOUT = 'Logout';
export const _GET_SHOP_CREDIT = 'GetShopCredit';
export const _GET_PENDING_ONLINE_WITHDRAWAL_COUNT = 'GetPendingOnlineWithdrawalCount';

/// <summary>
/// Author : CK
/// </summary>
export function getShopCredit() {
    return async (dispatch, getState) => {
        var userData = getState().authState.userData;

        if (userData) {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + userData.userId);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var shopData = responseJson[ApiKey._API_DATA_KEY];

                dispatch({
                    eghlLimit: shopData.eghlLimit ?? 0, 
                    creditLimit: shopData.shopProfile ? shopData.shopProfile.creditLimit : shopData.managerProfile ? shopData.managerProfile.creditLimit : 0,
                    type: _GET_SHOP_CREDIT
                });
            }
        }
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// expose login action as function to be called from classes
/// Nelson - if return status code is 102 means require login approval
/// </summary>
export function performLogin(username, password) {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });

        const PROCESSING_STATUS_CODE = 102;

        let params = {
            "username": username,
            "password": password,
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_LOGIN, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var userData = responseJson[ApiKey._API_DATA_KEY];

            sessionStorage.setItem(SessionKey._LOGIN_GUID, userData.userId);
            localStorage.setItem(SessionKey._LOGIN_GUID, userData.userId);

            dispatch({
                type: _LOGIN_SUCCESS,
                userData: userData,
            });
        }
        else if (responseJson[ApiKey._API_CODE_KEY] == PROCESSING_STATUS_CODE) {
            dispatch(initFirebaseDatabase(responseJson[ApiKey._API_DATA_KEY].userId,
                {
                    username: params.username,
                    password: params.password,
                    requestId: responseJson[ApiKey._API_DATA_KEY].requestId,
                    userId: responseJson[ApiKey._API_DATA_KEY].userId
                }
            ));

            dispatch(showMessage({
                type: AlertTypes._INFO,
                content: i18n.t(responseJson[ApiKey._API_MESSAGE_KEY]),
                showCancel: true,
                showConfirm: false,
                cancelBtnText: i18n.t("BTN_CANCEL"),
                closeOnClickOutside: false,
                onCancel: async () => {
                    var response = await ApiEngine.post(ApiUrl._API_UPDATE_SHOP_REQUEST +
                        "?Id=" + responseJson[ApiKey._API_DATA_KEY].requestId +
                        "&Status=" + CommonStatus._REJECT_STATUS +
                        "&UpdatedBy=" + responseJson[ApiKey._API_DATA_KEY].userId);

                    if (!response[ApiKey._API_SUCCESS_KEY]) {
                        dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                    }
                }
            }));
        }
        else {
            dispatch({
                type: _LOGIN_FAILED,
                message: responseJson[ApiKey._API_MESSAGE_KEY],
            });
            dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData) => {
    return {
        type: _LOGIN_SUCCESS,
        userData: userData,
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = (isEmergency) => {
    return async dispatch => {
        const response = await fetch(ApiUrl._API_LOGOUT + "?isEmergency=" + (isEmergency ? "true" : "false"), {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        });

        await response.json();

        dispatch({
            type: _LOGOUT
        });
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });
    }
}

/// <summary>
/// Author : CK
/// </summary>
export function getPendingOnlineWithdrawalCount() {
    return async (dispatch, getState) => {
        const PENDING_APPROVAL_STATUS = 1;
        var userData = getState().authState.userData;

        if (userData) {
            var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_ONLINE_WITHDRAWAL_REQUEST}?status=${PENDING_APPROVAL_STATUS}`, { headers: { "skipLoading": true } });

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var data = responseJson[ApiKey._API_DATA_KEY];
                var count = data['data'].length ?? 0;

                dispatch({
                    count: count,
                    type: _GET_PENDING_ONLINE_WITHDRAWAL_COUNT
                });
            }
        }
    }
}
