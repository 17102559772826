import React, { useRef, useEffect, useState } from 'react';
import { Panel, PanelBody } from 'components/panel/panel.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { AlertTypes, ApiKey, FilterStatus, Status, TransactionTypeId, Role, TransactionRequestStatus, FirebaseKey, TransactionType } from 'util/Constant.js';
import { ApiUrl, WebUrl } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, ignoreDecimal, isObjectEmpty, printReceipt, numberWithCurrencyFormat } from "../../util/Util";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocation, withRouter, useHistory } from "react-router-dom";
import EghlServiceDetail from '../../components/custom/EghlServiceDetail';

/// <summary>
/// Author: CK
/// </summary>
const EGHLReload = props => {
    const _CATEGORY_PAGE = "CATEGORY";
    const _SUBCATEGORY_PAGE = "SUBCATEGORY";
    const _DETAIL_PAGE = "DETAIL";

    var _dispatch = useDispatch();
    var _location = useLocation();
    var _history = useHistory();
    const { t } = useTranslation();
    var _eghlLimit = useSelector(state => state.authState.eghlLimit);
    var _userData = useSelector(state => state.authState.userData);
    const [currentServicePage, setCurrentServicePage] = useState(_CATEGORY_PAGE);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [serviceSubCategories, setServiceSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState();

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        // if (!(_userData.userRoleId == Role._SHOP && _userData.canEghlReload)) {
            navigateTo(_history, WebUrl._URL_MAIN);
            return;
        // }

        geteGHLServicesCategory();
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary>
    const geteGHLServicesCategory = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SERVICE_CATEGORY);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (responseJson[ApiKey._API_DATA_KEY].length) {
                setServiceCategories(responseJson[ApiKey._API_DATA_KEY]);
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const geteGHLServicesSubCategory = async (categoryId) => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SERVICE_SUBCATEGORY + "?categoryId=" + categoryId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCurrentServicePage(_SUBCATEGORY_PAGE);

            if (responseJson[ApiKey._API_DATA_KEY].length) {
                setServiceSubCategories(responseJson[ApiKey._API_DATA_KEY]);
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    return <>
        <div className="page-header row eghl-header">
            <h1>
                eGHL Reload
            </h1>
            <span>{t("EGHL_LIMIT")}: <strong>{numberWithCurrencyFormat(_eghlLimit)}</strong></span>
        </div>
        <Panel className="reload-panel">
            <PanelBody>
                <div className="container">
                    <div className='row' style={{ justifyContent: 'flex-end' }}>
                        {
                            currentServicePage != _CATEGORY_PAGE &&
                            <button
                                type="button"
                                className="btn btn-inverse m-b-10"
                                onClick={() => {
                                    if (currentServicePage == _SUBCATEGORY_PAGE) {
                                        setCurrentServicePage(_CATEGORY_PAGE);
                                    }
                                    else if (currentServicePage == _DETAIL_PAGE) {
                                        setCurrentServicePage(_SUBCATEGORY_PAGE);
                                        setSelectedSubCategory(null);
                                    }
                                }}
                            >
                                {t("BACK")}
                            </button>
                        }
                    </div>
                    <div className="row">
                        {
                            currentServicePage == _CATEGORY_PAGE ?
                                serviceCategories.map(i => {
                                    return <div className="col-md-3 col-sm-6 col-xs-6 eghl-service-col">
                                        <button
                                            onClick={() => {
                                                geteGHLServicesSubCategory(i.id);
                                            }}
                                            className="btn btn-eghl-service"
                                        >
                                            <img src={i.sc_img} alt={i.sc_code} />
                                            <div>{i.sc_name}</div>
                                        </button>
                                    </div>
                                })
                                :
                                currentServicePage == _SUBCATEGORY_PAGE ?
                                    serviceSubCategories.map(i => {
                                        return <div className="col-md-3 col-sm-6 col-xs-6 eghl-service-col">
                                            <button
                                                onClick={() => {
                                                    setSelectedSubCategory(i);
                                                    setCurrentServicePage(_DETAIL_PAGE);
                                                }}
                                                className="btn btn-eghl-service"
                                            >
                                                <img src={i.img} alt={i.ssc_name} />
                                                <div>{i.ssc_name}</div>
                                            </button>
                                        </div>
                                    })
                                    :
                                    currentServicePage == _DETAIL_PAGE ?
                                        <EghlServiceDetail subCategory={selectedSubCategory} />
                                        : <p className='text-center'>{t("NO_DATA_FOUND")}</p>
                        }
                    </div>
                </div>
            </PanelBody>
        </Panel>
    </>;
}

export default withRouter(EGHLReload);