import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import {
    Panel,
    PanelBody,
} from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Toast, ToastBody } from 'reactstrap';
import ReactTable from "../../../components/custom/ReactTable";
import { showResponseMessage, showMessage } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role, AlertTypes, TransactionType, TopUpStatus, AccessRight } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch, useSelector } from "react-redux";
import { checkIfPermissionExist } from '../../../util/PermissionChecker';
import CreateEWalletTransactionModal from '../../../components/custom/CreateEWalletTransactionModal';
import RegionFilterDualListBox from "../../../components/custom/RegionFilterDualListBox";

/// <summary>
/// Author : YJ
/// </summary>
const TopUpReport = props => {
    let _dispatch = useDispatch();
    var _userId = useSelector(state => state['authState']['userData']['userId']);

    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [createEWalletModal, setCreateEWalletModal] = useState(false);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [regions, setRegions] = useState([]);
    const [showToast, setShowToast] = useState(false);

    const _TYPE_OPTION = {
        ALL: -1,
        PENDING: 0,
        SUCCESS: 1,
        FAILED: 2
    };

    const _METHOD_OPTION = {
        ALL: -1,
        SYSTEM: 0,
        MANUAL: 1
    };

    const _TRANSACTION_TYPE_ID = {
        GPAY: 6,
        EGHL: 7,
        EWALLET: 8,
        DUITNOW: 9,
        VENDING_MACHINE: 12,
        MOBILE_PIN_TOPUP: 13,
        KINGPAY_DUITNOW: 14,
        SHOP_EGHL: 17
    };

    let _tableColumns = [
        {
            Header: "CREATED_DATE",
            accessor: "createdDate",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true,
        },
        {
            Header: "MEMBER",
            accessor: "memberUsername",
            disableSortBy: true
        },
        {
            Header: "SHOP",
            accessor: "shopUsername",
            disableSortBy: true
        },
        {
            Header: "TRANSACTION_ID",
            accessor: "transactionId",
            Cell: ({ row }) => {
                return (
                    <>
                        <span>{row.original.transactionId}</span>
                        &nbsp;
                        <button
                            className='btn btn-primary btn-icon btn-circle btn-sm'
                            style={{
                                'padding': '0',
                                'borderRadius': '50px'
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(row.original.transactionId);
                                setShowToast(!showToast);
                            }}
                        >
                            <i class="fas fa-copy"></i>
                        </button>
                    </>
                );
            },
            disableSortBy: true,
        },
        {
            Header: "TRANSACTION_TYPE",
            accessor: "typeName",
            Cell: ({ row }) => {
                return renderTransactionType(row.original.typeName);
            },
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return renderStatus(row.original.status);
            },
            disableSortBy: true
        },
        {
            Header: "INITIAL_CREDIT",
            accessor: "initialCredit",
            Cell: ({ row }) => {
                return row.original.initialCredit.toFixed(2)
            },
            Footer: ({ page }) => {
                const initialCreditTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.initialCredit);
                }, 0);

                return <span><b>{initialCreditTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount.toFixed(2)
            },
            Footer: ({ page }) => {
                const amountTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{amountTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "FINAL_CREDIT",
            accessor: "finalCredit",
            Cell: ({ row }) => {
                return row.original.finalCredit.toFixed(2)
            },
            Footer: ({ page }) => {
                const finalCreditTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.finalCredit);
                }, 0);

                return <span><b>{finalCreditTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "INITIAL_AMOUNT",
            accessor: "initialAmount",
            Cell: ({ row }) => {
                return row.original.initialAmount.toFixed(2)
            },
            Footer: ({ page }) => {
                const amountTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.initialAmount);
                }, 0);

                return <span><b>{amountTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PROCESSING_FEE",
            accessor: "processingFee",
            Cell: ({ row }) => {
                return row.original.processingFee.toFixed(2)
            },
            Footer: ({ page }) => {
                const amountTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.processingFee);
                }, 0);

                return <span><b>{amountTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PROCESSED_DATE",
            accessor: "processedDate",
            Cell: ({ row }) => {
                return !stringIsNullOrEmpty(row.original.processedDate) ? row.original.processedDate : '-'
            },
            disableSortBy: true
        },
        {
            Header: "BANK_TRANSACTION_ID",
            accessor: "bankTransactionId",
            Cell: ({ row }) => {
                return !stringIsNullOrEmpty(row.original.bankTransactionId) ? row.original.bankTransactionId : '-'
            },
            disableSortBy: true
        },
        {
            Header: "REMARK",
            accessor: "remark",
            disableSortBy: true,
            style: {
                width: 450,
                whiteSpace: 'break-spaces'
            },
            minWidth: 450
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                (row.original.status == TopUpStatus._PENDING || row.original.status == TopUpStatus._PENDING_APPROVAL || row.original.status == TopUpStatus._FAILED) &&
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            {
                                (row.original.status == TopUpStatus._PENDING || row.original.status == TopUpStatus._FAILED) && (row.original.typeName == TransactionType._TOP_UP_E_WALLET || row.original.typeName == TransactionType._TOP_UP_GPAY_DUITNOW || row.original.typeName == TransactionType._KINGPAY_DUITNOW) &&
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                                        showCancel: true,
                                        required: true,
                                        inputType: 'password',
                                        validationMsg: t('INVALID_PASSWORD'),
                                        confirmBtnText: t("CONFIRM"),
                                        type: AlertTypes._INPUT,
                                        onConfirm: async (value) => {
                                            await verifyPassword(value, row.original, false, true);
                                        }
                                    }));
                                }}><span className="text-warning">{t("VALIDATE_TRANSACTION")}</span></DropdownItem>
                            }
                            {
                                row.original.typeName == TransactionType._MOBILE_PIN_TOPUP && row.original.status != TopUpStatus._SUCCESS &&
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        content: t("ENTER_AMOUNT_1") + ` (${t("MASTER_APPROVE")})`,
                                        showCancel: true,
                                        required: true,
                                        validationMsg: t('REQUIRED'),
                                        confirmBtnText: t("CONFIRM"),
                                        type: AlertTypes._INPUT,
                                        onConfirm: async (amt) => {
                                            _dispatch(showMessage({
                                                content: t("VERIFY_PASSWORD_BEFORE_CONTINUE") + ` (${t("MASTER_APPROVE")})`,
                                                showCancel: true,
                                                required: true,
                                                inputType: 'password',
                                                validationMsg: t('INVALID_PASSWORD'),
                                                confirmBtnText: t("CONFIRM"),
                                                type: AlertTypes._INPUT,
                                                onConfirm: async (value) => {
                                                    await verifyPassword(value, row.original, true, false, amt);
                                                }
                                            }));
                                        }
                                    }));
                                }}><span className="text-danger">{t("MASTER_APPROVE")}</span></DropdownItem>
                            }
                            {
                                row.original.typeName != TransactionType._KINGPAY_DUITNOW &&
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                                        showCancel: true,
                                        required: true,
                                        inputType: 'password',
                                        validationMsg: t('INVALID_PASSWORD'),
                                        confirmBtnText: t("CONFIRM"),
                                        type: AlertTypes._INPUT,
                                        onConfirm: async (value) => {
                                            await verifyPassword(value, row.original, true);
                                        }
                                    }));
                                }}><span className="text-warning">{t("APPROVE")}</span></DropdownItem>
                            }
                            {
                                row.original.typeName != TransactionType._KINGPAY_DUITNOW && row.original.status != TopUpStatus._FAILED &&
                                <DropdownItem onClick={() => {
                                    _dispatch(showMessage({
                                        content: t("VERIFY_PASSWORD_BEFORE_CONTINUE"),
                                        showCancel: true,
                                        required: true,
                                        inputType: 'password',
                                        validationMsg: t('INVALID_PASSWORD'),
                                        confirmBtnText: t("CONFIRM"),
                                        type: AlertTypes._INPUT,
                                        onConfirm: async (value) => {
                                            await verifyPassword(value, row.original, false);
                                        }
                                    }));
                                }}><span className="text-warning">{t("REJECT")}</span></DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author : CK
    /// </summary>
    useEffect(() => {
        register({ name: 'operatedShop' });
        register({ name: 'startDate' }, { required: true });
        register({ name: 'endDate' }, { required: true });

        setValue('operatedShop', []);
        setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author: Wind
    /// </summary>
    useEffect(() => {
        if (showToast) {
            setTimeout(() => setShowToast(!showToast), 3000)
        }
    }, [showToast])

    /// <summary>
    /// Author: Nelson
    /// </summary>
    async function verifyPassword(password, transactionData, isApproved, isValidate = false, approveAmount = 0) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_PASSWORD}?userId=${_userId}&password=${password}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (isValidate) {
                ValidateTransaction(transactionData);
            }
            else {
                SetTransactionStatus(transactionData, isApproved, approveAmount);
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    function renderStatus(status) {
        let statusString = "";
        let spanClass = "";

        switch (status) {
            case TopUpStatus._SUCCESS.toString():
                spanClass = "badge-green";
                statusString = t("SUCCESS");
                break;
            case TopUpStatus._FAILED.toString():
                spanClass = "badge-red";
                statusString = t("FAILED");
                break;
            case TopUpStatus._PENDING_APPROVAL.toString():
                spanClass = "badge-primary";
                statusString = t("PENDING_APPROVAL");
                break;
            default:
                spanClass = "badge-default";
                statusString = t("PENDING");
        }

        return <span className={"badge " + spanClass}> {statusString}</span>
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    function renderTransactionType(transactionType) {
        let transactionTypeString = "";

        switch (transactionType) {
            case TransactionType._TOP_UP_GPAY:
                transactionTypeString = t("GPAY");
                break;
            case TransactionType._TOP_UP_EGHL:
                transactionTypeString = t("EGHL");
                break;
            case TransactionType._TOP_UP_E_WALLET:
                transactionTypeString = t("E_WALLET");
                break;
            case TransactionType._TOP_UP_GPAY_DUITNOW:
                transactionTypeString = t("DUITNOW");
                break;
            case TransactionType._VENDING_MACHINE:
                transactionTypeString = t("VENDING_MACHINE");
                break;
            case TransactionType._MOBILE_PIN_TOPUP:
                transactionTypeString = t("MOBILE_PIN_TOPUP");
                break;
            case TransactionType._KINGPAY_DUITNOW:
                transactionTypeString = t("KINGPAY_DUITNOW");
                break;
            case TransactionType._SHOP_EGHL_LIMIT:
                transactionTypeString = t("SHOP_PURCHASE_EGHL");
                break;
        }

        return <span className="badge badge-primary">{transactionTypeString}</span>
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function init() {
        await getShopOption();
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function SetTransactionStatus(data, status, approveAmount = 0) {
        var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_TOPUP_TRANSACTION + "?transactionId=" + data.transactionId + "&status=" + status + "&approveAmount=" + approveAmount);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function ValidateTransaction(data) {
        var responseJson = await ApiEngine.post(ApiUrl._API_VALIDATE_EWALLET_TRANSACTION + "?transactionId=" + data.transactionId + "&isDuitNow=" + (data.typeName == TransactionType._TOP_UP_GPAY_DUITNOW || data.typeName == TransactionType._KINGPAY_DUITNOW));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }
        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getShopOption() {
        var apiUrl = ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP;
        var responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                regionOptions.push({ label: key, value: key });

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
            setRegions(regionOptions);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "Username": data.username,
            "TransactionId": data.transactionId,
            "TargetShops": data.operatedShop,
            "Status": parseInt(data.status),
            "TransactionTypeId": parseInt(data.transactionType),
            "MethodId": parseInt(data.method)
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_TOPUP_TRANSACTION);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable className="col-nowrap" showOverallInfo={true} initialPageSize={500} columns={_tableColumns} fetchUrl={apiUrl} postData={postData} renderFooter={true} exportRequired={true} />, [postData, apiUrl])

    return (
        <div>
            <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("TOP_UP_REPORT")}</h1>
                {
                    checkIfPermissionExist(AccessRight._TOP_UP_REPORT_PERMISSION, true) &&
                    <button
                        type="button"
                        className="btn btn-primary"
                        style={{ float: "right" }}
                        onClick={() => {
                            setCreateEWalletModal(true);
                        }}
                    >
                        {t('CREATE_EWALLET_TRANSACTION')}
                    </button>
                }
            </div>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("USERNAME")}</label>
                                    <input type="text" name="username" ref={register}
                                        className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSACTION_ID")}</label>
                                    <input type="text" name="transactionId" ref={register}
                                        className="form-control form-control-lg" />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={TopUpStatus._PENDING}>{t("PENDING")}</option>
                                        <option value={TopUpStatus._SUCCESS}>{t("SUCCESS")}</option>
                                        <option value={TopUpStatus._FAILED}>{t("FAILED")}</option>
                                        <option value={TopUpStatus._PENDING_APPROVAL}>{t("PENDING_APPROVAL")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSACTION_TYPE")}</label>
                                    <select name="transactionType" ref={register} className="form-control form-control-lg">
                                        <option value={_TYPE_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.GPAY}>{t("GPAY")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.DUITNOW}>{t("DUITNOW")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.EWALLET}>{t("E_WALLET")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.EGHL}>{t("EGHL")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.MOBILE_PIN_TOPUP}>{t("MOBILE_PIN_TOPUP")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.KINGPAY_DUITNOW}>{t("KINGPAY_DUITNOW")}</option>
                                        <option value={_TRANSACTION_TYPE_ID.SHOP_EGHL}>{t("SHOP_PURCHASE_EGHL")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("METHOD")}</label>
                                    <select name="method" ref={register} className="form-control form-control-lg">
                                        <option value={_METHOD_OPTION.ALL}>{t("ALL")}</option>
                                        <option value={_METHOD_OPTION.SYSTEM}>{t("SYSTEM")}</option>
                                        <option value={_METHOD_OPTION.MANUAL}>{t("MANUAL")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("OPERATED_SHOP")}</label>
                                    <RegionFilterDualListBox
                                        name={"operatedShop"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('operatedShop', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <Toast style={{ position: 'fixed', bottom: '1rem', left: '50%' }} isOpen={showToast} transition={{ key: "1", transitionLeaveTimeout: 3000 }}>
                <ToastBody>
                    {t('COPIED')}
                </ToastBody>
            </Toast>
            <CreateEWalletTransactionModal
                visible={createEWalletModal}
                onFinish={(transactionId) => {
                    if (!stringIsNullOrEmpty(transactionId)) {
                        // init();
                    }

                    setCreateEWalletModal(false);
                }} />
        </div>
    );
};

export default TopUpReport;