import React, { useMemo, useEffect, useState } from 'react';
import { Panel, PanelBody } from 'components/panel/panel.jsx';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationButton from '../../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import ReactTable from 'components/custom/ReactTable';
import { FilterStatus, WebUrl, ApiKey, ApiUrl, Role, AccessRight, Status, AlertTypes } from 'util/Constant';
import { navigateTo, createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, getValidationMessage } from "../../../util/Util";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../../util/ApiEngine.js';
import { showMessage, showResponseMessage } from 'redux/AppAction';
import AccountStatusModal from '../../../components/custom/AccountStatusModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import moment from "moment";

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
const ManageMember = props => {
    var _history = useHistory();
    var _location = useLocation();
    const { t } = useTranslation();
    let _dispatch = useDispatch();
    const _userData = useSelector(state => state.authState.userData);
    const { register, handleSubmit, watch, setValue } = useForm();
    const { register: registerSetCreditForm, errors: errorsSetCreditForm,
        handleSubmit: handleSubmitSetCreditForm, unregister: unregisterSetCreditForm,
        setValue: setValueSetCreditForm } = useForm();
    const [regionOptions, setRegionOptions] = useState([]);
    const [memberApiUrl, setMemberApiUrl] = useState('');
    const [deactivateAccountDetail, setDeactivateAccountDetail] = useState({});
    const [isCreditModalVisible, setIsCreditModalVisible] = useState(false);
    const [creditShopOption, setCreditShopOption] = useState([]);
    const [creditShopId, setCreditShopId] = useState('');
    const [selectedMember, setSelectedMember] = useState('');

    const _tableColumns = useMemo(() => [
        {
            Header: 'USERNAME',
            accessor: 'username',
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.username} ({row.original.regionName})
                    </span>
                )
            }
        },
        {
            Header: 'SHOP',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row.original.shopUsername} ({row.original.shopName})
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: 'STATUS',
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <span>
                        {
                            row.original.status == FilterStatus._ACTIVE ?
                                <span class="badge badge-secondary badge-green">{t("ACTIVE")}</span> :
                                <span class="badge badge-secondary badge-danger">{t("SUSPENDED") + " (" + row.original.invalidLoginCount + ")"}</span>
                        }
                    </span>
                )
            },
            disableSortBy: true
        },
        {
            Header: 'TOTAL_REWARD',
            accessor: row => row.memberProfile.totalRewardPoint.toFixed(2)
        },
        {
            Header: 'TOTAL_CREDIT',
            accessor: row => row.memberProfile.totalCredit.toFixed(2)
        },
        {
            Header: 'EXPERIENCE',
            Cell: ({ row }) => {
                let experienceDetail = row.original.experienceDetail;
                return <span>{experienceDetail ? numberWithCurrencyFormat(experienceDetail.experience, 3) + " Exp (" + experienceDetail.rankName + ")" : ""}</span>
            }
        },
        {
            Header: 'LAST_LOGIN',
            Cell: ({ row }) => {
                return <span>{moment(row.original?.memberLastActiveDetails?.lastActive).format("YYYY-MM-DD HH:mm:ss")}</span>
            }
        },
        {
            Header: 'CREATED_TIME',
            accessor: 'createdTimeString',
        },
        {
            Header: 'PERMISSIONS',
            Cell: ({ row }) => {
                let canCreatePlayer = row.original.memberProfile?.createPlayerEnable;
                let canReload = row.original.memberProfile?.reloadEnable;

                return <div>
                    <span>
                        {t("CAN_RELOAD")} {canReload ? <i className={"fas fa-check-circle text-green"}></i> : <i className={"fas fa-times-circle text-red"}></i>}
                    </span>
                    <br />
                    <span>
                        {t("CAN_CREATE_PLAYER")} {canCreatePlayer ? <i className={"fas fa-check-circle text-green"}></i> : <i className={"fas fa-times-circle text-red"}></i>}
                    </span>
                </div>
            },
            disableSortBy: true,
            style: { width: "170px" }
        },
        {

            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._MEMBER_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu style={{ 'overflow-y': 'scroll' }}>
                            {_userData.accessibleRegion.filter(i => i == row.original.regionId).length != 0 &&
                                <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_CREATE_OR_UPDATE_MEMBER, row.original) }}><span>{t("EDIT")}</span></DropdownItem>
                            }
                            {_userData.accessibleRegion.filter(i => i == row.original.regionId).length != 0 && _userData.isAdminLevel &&
                                <DropdownItem onClick={() => {
                                    initSetCreditModel(row.original);
                                }}><span>{t("SET_CREDIT")}</span></DropdownItem>
                            }
                            <DropdownItem onClick={() => { resetPassword(row.original.id) }}><span>{t("RESET_PASSWORD")}</span></DropdownItem>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_MEMBER_PLAYER, row.original) }}><span>{t("VIEW_PLAYER")}</span></DropdownItem>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_MEMBER_DOWNLINE, row.original) }}><span>{t("VIEW_DOWNLINE")}</span></DropdownItem>
                            {
                                _userData.isAbleViewLastMessage &&
                                <DropdownItem onClick={() => { getUserLastMessage(row.original.id); }}><span>{t("GET_LAST_MESSAGE")}</span></DropdownItem>
                            }
                            {
                                (row.original.bonusEnable != null && row.original.bonusEnable) && 
                                <DropdownItem onClick={() => { unlockBonusCheck(row.original.id); }}><span className='text-primary'>{t("UNLOCK_BONUS_CHECK")}</span></DropdownItem>
                            }
                            <DropdownItem onClick={() => {
                                let actionName = row.original.memberProfile?.reloadEnable ? t("BLOCK") : t("UNBLOCK");

                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') + " " + actionName + " " + t('CAN_RELOAD'),
                                    onConfirm: () => {
                                        let status = row.original.memberProfile?.reloadEnable ? false : true;
                                        updateMemberProfilePermission(row.original.id, status, true)
                                    }
                                }));
                            }}>
                                <span className={row.original.memberProfile?.reloadEnable ? "text-warning" : "text-green"}>
                                    {row.original.memberProfile?.reloadEnable ? t("BLOCK") : t("UNBLOCK")} {t("CAN_RELOAD")}
                                </span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                let actionName = row.original.memberProfile?.createPlayerEnable ? t("BLOCK") : t("UNBLOCK");

                                _dispatch(showMessage({
                                    type: AlertTypes._WARNING,
                                    title: t('ARE_YOU_SURE'),
                                    showCancel: true,
                                    content: t('ARE_YOU_SURE_YOU_WANT_TO') + " " + actionName + " " + t('CAN_CREATE_PLAYER'),
                                    onConfirm: () => {
                                        let status = row.original.memberProfile?.createPlayerEnable ? false : true;
                                        updateMemberProfilePermission(row.original.id, status, false)
                                    }
                                }));
                            }}>
                                <span className={row.original.memberProfile?.createPlayerEnable ? "text-warning" : "text-green"}>
                                    {row.original.memberProfile?.createPlayerEnable ? t("BLOCK") : t("UNBLOCK")} {t("CAN_CREATE_PLAYER")}
                                </span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                let currentStatus = row.original.status;
                                setDeactivateAccountDetail({
                                    ...row.original,
                                    fromStatus: currentStatus,
                                    toStatus: currentStatus == Status._ENABLED ? Status._DISABLED : Status._ENABLED
                                });
                            }}><span className="text-warning">{row.original.status == Status._ENABLED ? t("BLOCK") : t("UNBLOCK")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                let currentStatus = row.original.status;
                                setDeactivateAccountDetail({
                                    ...row.original,
                                    fromStatus: currentStatus,
                                    toStatus: Status._DELETED
                                });
                            }}><span className="text-red">{t("DELETE")}</span></DropdownItem>
                            {/* <DropdownItem onClick={() => { performRolloverReset(row.original.id) }}><span>{t("ROLLOVER_SYNC")}</span></DropdownItem> */}
                            <DropdownItem onClick={() => {
                                const win = window.open(WebUrl._URL_VIEW_USER_ACTION_LOG + "?username=" + row.original.username
                                    , "_blank");
                                win.focus();
                            }}>
                                <span>{t('VIEW_USER_ACTION_LOG')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={() => {
                                const win = window.open(WebUrl._URL_VIEW_USER_TRANSACTION_LOG + "?username=" + row.original.username
                                    , "_blank");
                                win.focus();
                            }}>
                                <span>{t('VIEW_USER_TRANSACTION_LOG')}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ], [_userData]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function saveSession() {
        var data = watch();
        sessionStorage.setItem("memberUsername", data.username);
        sessionStorage.setItem("memberRegionId", data.regionId);
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>      
    async function getUserLastMessage(id) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_LAST_MESSAGE + "?userId=" + id);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY], null, false));
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_REGION)

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var regionList = [];
            regionList.push({ label: "ALL", value: "" });
            responseJson[ApiKey._API_DATA_KEY].forEach(function (region) {
                regionList.push({ label: region.name, value: region.id });
            });

            setRegionOptions(regionList);
        }

        setValue("regionId", _location.state ? _location.state.regionId : sessionStorage.getItem("memberRegionId"));

        if (_location.state) {
            handleSubmit(onSubmit)();
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function initSetCreditModel(data) {
        registerSetCreditForm({ name: 'creditShopId' }, { required: true });
        var shopUserList = await getShopUser(data.regionId);

        if (shopUserList.filter(i => i.value == data.shopId).length > 0) {
            setValueSetCreditForm("creditShopId", shopUserList.filter(i => i.value == data.shopId)[0].value);
            setCreditShopId(shopUserList.filter(i => i.value == data.shopId)[0].value);
        }

        setSelectedMember(data);
        setIsCreditModalVisible(true);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function getShopUser(regionId) {
        const shopList = [];
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP + "&RegionId=" + regionId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            responseJson[ApiKey._API_DATA_KEY].forEach(function (shop) {
                shopList.push({ label: shop.username + " (" + shop.shopName + ")", value: shop.id });
            });

            setCreditShopOption(shopList);
        }

        return shopList;
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function performRolloverReset(memberId) {
        var apiUrl = ApiUrl._API_PERFORM_MEMBER_ROLLOVER_RESET + "?memberId=" + memberId;
        var responseJson = await ApiEngine.post(apiUrl);
        var message = responseJson[ApiKey._API_MESSAGE_KEY];

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            message = "ROLLOVER_SYNC_STARTED_CHECK_AGAIN_AFTER_5_MIN";
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(message)));
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function resetPassword(userId) {
        try {
            var apiUrl = ApiUrl._API_RESET_MEMBER_PASSWORD + "?userId=" + userId;
            var responseJson = await ApiEngine.post(apiUrl);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (responseJson[ApiKey._API_DATA_KEY] == null || isObjectEmpty(responseJson[ApiKey._API_DATA_KEY])) {
                    _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
                }
                else {
                    let responseData = responseJson[ApiKey._API_DATA_KEY];
                    _dispatch(showMessage({
                        type: AlertTypes._WARNING,
                        title: t('ARE_YOU_SURE'),
                        content: t('RESET_PASSWORD_ATTEMPT_CHARGE', { attempt: responseData.attempt, charge: responseData.charge }),
                        showCancel: true,
                        onConfirm: () => resetPasswordWithCharges(userId)
                    }));
                }
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function resetPasswordWithCharges(userId) {
        var apiUrl = ApiUrl._API_RESET_MEMBER_PASSWORD + "?userId=" + userId + "&chargeConfirmation=true";

        var responseJson = await ApiEngine.post(apiUrl);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            onSubmit({ username: watch('username'), regionId: watch('regionId') })
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const onSubmit = async (data, e) => {
        if (stringIsNullOrEmpty(data.username)) {
            _dispatch(showResponseMessage(false, t("PLEASE_ENTER_USERNAME")));
            return;
        }

        var rawUrl = ApiUrl._API_GET_USERS + "?RoleId=" + Role._MEMBER + "&v=" + moment.now();

        if (Object.keys(data).length != 0) {
            rawUrl += "&RegionId=" + data.regionId + "&Username=" + data.username;
        }

        saveSession();

        setMemberApiUrl(rawUrl);
    };

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function userStatusHandler(userId, status, remark = "") {
        let params = {
            id: userId,
            status: status,
            remark: remark
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER_ACCOUNT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(onSubmit)();
        }

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function cleanupSetCreditModal() {
        setCreditShopId('');
        unregisterSetCreditForm("creditShopId");
        setIsCreditModalVisible(false);
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function onSubmitSetCredit(data, e) {
        try {
            let setCreditAmount = parseFloat(data.setCreditAmount);

            if (isNaN(setCreditAmount) || parseFloat(selectedMember.memberProfile.totalCredit) + setCreditAmount < 0) {
                throw t("INVALID_RELOAD_AMOUNT");
            }

            var responseJson = await ApiEngine.post(ApiUrl._API_RELOAD_CREDIT + "?amount=" + setCreditAmount + "&MemberId=" + selectedMember.id + "&shopId=" + data.creditShopId);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t("OPERATION_SUCCESS")));
                handleSubmit(onSubmit)();
                cleanupSetCreditModal();
            }
            else {
                handleSubmit(onSubmit)();
                throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author : CK
    /// </summary>
    async function updateMemberProfilePermission(userId, status, isCanReloadPermission) {
        try {
            // default set createPlayerEnable bit
            let params = {
                id: userId,
                createPlayerEnable: status
            };

            // if isCanReloadPermission, then set reloadEnable bit
            if (isCanReloadPermission) {
                params = {
                    id: userId,
                    reloadEnable: status
                };
            }

            var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_MEMBER_PROFILE_PERMISSION, createMultiPartFormBody(params));

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t("OPERATION_SUCCESS")));
                handleSubmit(onSubmit)();
            }
            else {
                throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        }
        catch (err) {
            _dispatch(showResponseMessage(false, err));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>      
    async function unlockBonusCheck(id) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_BONUS_UNLOCK_CHECK}/${id}`);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    handleSubmit(onSubmit)();
                }
            }
        }));
    }

    return <>
        <div class="header-wrapper m-0 flex-lg-row flex-column align-items-start">
            <h1 className="page-header">{t("MANAGE_MEMBER")}</h1>
            {
                checkIfPermissionExist(AccessRight._MEMBER_CONTROL_PERMISSION, true) &&
                <NavigationButton history={_history} url={WebUrl._URL_ADMIN_CREATE_OR_UPDATE_MEMBER} buttonText={t("ADD_NEW_MEMBER")} />
            }
        </div>
        <AccountStatusModal
            visible={!isObjectEmpty(deactivateAccountDetail)}
            detail={deactivateAccountDetail}
            onFinish={(isConfirm, remark) => {
                if (isConfirm) {
                    userStatusHandler(deactivateAccountDetail.id, deactivateAccountDetail.toStatus, remark)
                }
                setDeactivateAccountDetail({});
            }} />
        <Panel>
            <PanelBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("USERNAME")}</label>
                                <input className="form-control form-control-lg" id="username" name="username" defaultValue={_location.state ? _location.state.memberUsername : sessionStorage.getItem("memberUsername")}
                                    type="text" ref={register} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label>{t("REGION")}</label>
                                <select name="regionId" ref={register}
                                    className="form-control form-control-lg">
                                    {regionOptions.map((region) => <option value={region.value}>{region.label}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group form-button">
                                <button type="submit" class="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBody>
        </Panel>
        <Panel>
            <PanelBody>
                <ReactTable columns={_tableColumns} fetchUrl={memberApiUrl} />
            </PanelBody>
        </Panel>
        {isCreditModalVisible &&
            <Modal isOpen={isCreditModalVisible} toggle={() => { cleanupSetCreditModal() }}>
                <form onSubmit={handleSubmitSetCreditForm(onSubmitSetCredit)}>
                    <ModalHeader toggle={() => { cleanupSetCreditModal() }}><h4>{t("SET_CREDIT")}</h4></ModalHeader>
                    <ModalBody>
                        <div className="form-group m-b-15 ">
                            <label>{t("USERNAME")}</label>
                            <input
                                className="form-control form-control-lg m-b-5"
                                type="text"
                                name="memberUsername"
                                disabled={true}
                                value={selectedMember ? selectedMember.username : ""}
                            />
                        </div>
                        <div className="form-group m-b-15 ">
                            <label>{t("SHOP")}</label>
                            <Select name="creditShopId" options={creditShopOption}
                                value={creditShopOption.filter(option => option.value == creditShopId)}
                                placeholder=""
                                onChange={(e) => {
                                    setValueSetCreditForm("creditShopId", e.value);
                                    setCreditShopId(e.value);
                                }} />
                            {errorsSetCreditForm.creditShopId && <div className="invalid-feedback">{t(getValidationMessage(errorsSetCreditForm.creditShopId))}</div>}
                        </div>
                        <div className="form-group m-b-15 ">
                            <label>{t("TOTAL_CREDIT")}</label>
                            <input
                                className="form-control form-control-lg m-b-5"
                                type="decimal"
                                name="memberTotalCredit"
                                disabled={true}
                                value={selectedMember ? selectedMember.memberProfile.totalCredit.toFixed(2) : 0}
                            />
                        </div>
                        <div className="form-group m-b-15 ">
                            <label>{t("WALLET_CREDIT")}</label>
                            <input
                                className="form-control form-control-lg m-b-5"
                                type="number"
                                step={0.01}
                                name="setCreditAmount"
                                ref={registerSetCreditForm({ required: true })} />
                            {errorsSetCreditForm.setCreditAmount && <div className="invalid-feedback">{t(getValidationMessage(errorsSetCreditForm.setCreditAmount))}</div>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                        <button type="button" className="btn btn-danger btn-action" onClick={() => cleanupSetCreditModal()}>{t("BTN_CANCEL")}</button>
                    </ModalFooter>
                </form>
            </Modal>
        }
    </>;
}

export default ManageMember;