import React from "react";
import { Redirect } from "react-router";
import { WebUrl, LoginType, AccessRight } from "util/Constant";

import Login from "./../pages/home/Login.js";

//admin module
import ManageShop from "./../pages/admin/account/ManageShop.js";
import ShopDetail from "./../pages/admin/account/ShopDetail.js";
import ManageManager from "./../pages/admin/account/ManageManager";
import ManagerDetail from "./../pages/admin/account/ManagerDetail";
import ManageRole from "./../pages/admin/account/ManageRole";
import RoleDetail from "./../pages/admin/account/RoleDetail";
import ManageAdmin from "./../pages/admin/account/ManageAdmin";
import AdminDetail from "./../pages/admin/account/AdminDetail";
import ManagePlayer from "./../pages/admin/account/ManagePlayer";
import ManageMember from "./../pages/admin/account/ManageMember";
import MemberDetail from "./../pages/admin/account/MemberDetail";
import ViewAnnouncement from "./../pages/admin/account/ViewAnnouncement";
import MemberPlayerControl from "./../pages/admin/account/MemberPlayerControl";
import ManageShopApi from "./../pages/admin/account/ManageShopApi";
import ManageAgent from "./../pages/admin/account/ManageAgent.js";
import AgentDetail from "./../pages/admin/account/AgentDetail.js";

import Dashboard from "./../pages/admin/Dashboard.js";

import ManageVendor from "./../pages/admin/vendor/ManageVendor";
import VendorDetail from "./../pages/admin/vendor/VendorDetail";
import ManageVendorApi from "./../pages/admin/vendor/ManageVendorApi";
import VendorApiDetail from "./../pages/admin/vendor/VendorApiDetail";

import ManageSystemSettings from "./../pages/admin/configuration/ManageSystemSettings.js";
import ManageSmsSettings from "./../pages/admin/configuration/ManageSmsSettings.js";
import SmsDetails from "./../pages/admin/configuration/SmsDetails.js";
import ManageShopMessage from "./../pages/admin/configuration/ManageShopMessage.js";
import ShopMessageDetail from "../pages/admin/configuration/ShopMessageDetail.js";
import ManageSystemMessage from "./../pages/admin/configuration/ManageSystemMessage.js";
import SystemMessageDetail from "../pages/admin/configuration/SystemMessageDetail.js";
import ManagePhoneMessage from "./../pages/admin/configuration/ManagePhoneMessage.js";
import PhoneMessageDetail from "../pages/admin/configuration/PhoneMessageDetail.js";
import OperationLog from "../pages/admin/configuration/OperationLog.js";
import ManagePromotion from "./../pages/admin/configuration/ManagePromotion";
import ManagePromotionDetail from "../pages/admin/configuration/ManagePromotionDetail";
import ManageLuckyNumber from "../pages/admin/configuration/ManageLuckyNumber";

import ManageMiniProgram from "../pages/admin/configuration/ManageMiniProgram";
import MiniProgramDetail from "../pages/admin/configuration/MiniProgramDetail";
import ManagePaymentGateway from "../pages/admin/configuration/ManagePaymentGateway";
import ManagePaymentGatewayDetail from "../pages/admin/configuration/ManagePaymentGatewayDetail";

import ManageIPBlackList from "../pages/admin/configuration/ManageIPBlackList";

import ManageShopLogin from "../pages/admin/configuration/ManageShopLogin";

import ManageAppealTransaction from "../pages/admin/configuration/ManageAppealTransaction";

import ManageBonus from "../pages/admin/configuration/ManageBonus";

//report
import SetLimitReport from "../pages/admin/report/SetLimitReport";
import SetScoreReport from "../pages/admin/report/SetScoreReport";
import SalesReport from "../pages/admin/report/SalesReport";
import RewardReport from "../pages/admin/report/RewardReport";
import ClaimRewardReport from "../pages/admin/report/ClaimRewardReport";
import ManagerLimitReport from "../pages/admin/report/ManagerLimitReport";
import KioskTimeoutReport from "../pages/admin/report/KioskTimeoutReport";
import AgentCommissionReport from "../pages/admin/report/AgentCommissionReport";
import TopUpReport from "../pages/admin/report/TopUpReport";
import CreditTransferReport from "../pages/admin/report/CreditTransferReport";
import StrikeReport from "../pages/admin/report/StrikeReport";
import RolloverReport from "../pages/admin/report/RolloverReport";
import UpperLimitWarningReport from "../pages/admin/report/UpperLimitWarningReport";
import UserExperienceReport from "../pages/admin/report/UserExperienceReport";
import TurnoverReport from "../pages/admin/report/TurnoverReport";
import TurnoverRewardReport from "../pages/admin/report/TurnoverRewardReport";
import LuckySpinWinner from "../pages/admin/report/LuckySpinWinner";
import KogWinner from "../pages/admin/report/KogWinner";
import AgentNetProfitReport from "../pages/admin/report/AgentNetProfitReport";
import LottoSeamlessSettlementReport from "../pages/admin/report/LottoSeamlessSettlementReport";
import ShopEghlReport from "pages/admin/report/ShopEghlReport.js";

//shop module
import Report from "./../pages/shop/Report.js";
import MemberDetails from "./../pages/shop/MemberDetails.js";
import Reload from "pages/shop/Reload.js";
import EGHLReload from "pages/shop/EGHLReload.js";
import ClaimBonus from "pages/shop/ClaimBonus.js";
import Search from "./../pages/shop/Search.js";
import BlockPuk from "./../pages/shop/BlockPuk.js";
import RegisterMember from "./../pages/shop/RegisterMember";
import RegisterPlayer from "./../pages/shop/RegisterPlayer";
import ShopAgentCommissionReport from "./../pages/shop/ShopAgentCommissionReport";
import ShopCreditTopupReport from "./../pages/shop/CreditTopupReport";
import ShopCreditTransferReport from "./../pages/shop/CreditTransferReport";
import PlayerReload from "./../pages/shop/PlayerReload.js";
import Announcement from "./../pages/shop/Announcement";
import TransactionRequest from "./../pages/shop/TransactionRequest";
import ShopLimitApprovalReport from "../pages/shop/ShopLimitApprovalReport";

import ManageVendorCategory from "./../pages/admin/vendor/ManageVendorCategory";
import VendorCategoryDetail from "./../pages/admin/vendor/VendorCategoryDetail";

import ManageRank from "../pages/admin/configuration/ManageRank";
import RankDetail from "../pages/admin/configuration/RankDetail";

import ManageNotificationSettings from "./../pages/admin/configuration/ManageNotificationSettings";
import NotificationDetails from "./../pages/admin/configuration/NotificationDetails";
import ManageMemberDownline from "./../pages/admin/account/ManageMemberDownline";

import ManageAppAnnoucement from "./../pages/admin/configuration/ManageAppAnnoucement";
import AppAnnoucementDetail from "./../pages/admin/configuration/AppAnnoucementDetail";

import ManageKogEvent from "./../pages/admin/configuration/ManageKogEvent";
import KogEventDetail from "./../pages/admin/configuration/KogEventDetail";
import KogEventRanking from "./../pages/admin/configuration/KogEventRanking";

import ManageFeedbackSettings from "./../pages/admin/configuration/ManageFeedbackSettings";
import MemberFeedbackReport from "./../pages/admin/configuration/MemberFeedbackReport";
import ShopFeedbackReport from "./../pages/admin/configuration/ShopFeedbackReport";

import ManageBanner from "./../pages/admin/configuration/ManageBanner";
import ManageBannerDetail from "./../pages/admin/configuration/BannerDetail";

import MemberEcreditTransferLog from "../pages/admin/report/MemberEcreditTransferLog";
import RegionEcreditTransferLog from "../pages/admin/report/RegionEcreditTransferLog";

import ViewUserActionLog from "../pages/admin/report/UserActionLog";
import ViewTransactionLog from "../pages/admin/report/UserTransactionLog";

import OverallDownlineReport from "../pages/admin/report/OverallDownlineReport";
import RankSummaryReport from "../pages/admin/report/RankSummaryReport";

import ApiLog from "../pages/admin/report/ApiLog.js";
import AgentApiDetail from "pages/admin/account/AgentVendorApiDetail.js";
import AgentVendorApiDetail from "pages/admin/account/AgentVendorApiDetail.js";
import AgentWinLoss from "pages/admin/report/AgentWinLossReport";
import AgentWinLossReport from "pages/admin/report/AgentWinLossReport";
import AgentWinLossReport2 from "pages/admin/report/AgentWinLossReport2";
import ManageVipMember from "pages/admin/configuration/ManageVipMember.js";

import OnlineWithdrawalRequest from "../pages/admin/online-withdrawal/OnlineWithdrawalRequest";
import ManagerLimitTransactionReport from "pages/admin/report/ManagerLimitTransactionReport";
import PlayerCreationReport from "../pages/shop/PlayerCreationReport";

import ManageThirdParty from "pages/admin/third-party/ManageThirdParty";
import ThirdPartyDetail from "pages/admin/third-party/ThirdPartyDetail.js";

import ManageThirdPartyApi from "pages/admin/third-party/ManageThirdPartyApi";
import ThirdPartyApiDetail from "pages/admin/third-party/ThirdPartyApiDetail.js";

import UserGrowthReport from "pages/admin/report/UserGrowthReport.js";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export const _LOGIN_ROUTE = {
	path: WebUrl._URL_MAIN,
	exact: false,
	authRequired: false,
	component: () => <Redirect to={WebUrl._URL_LOGIN} />,
};

export const _ROUTES = [
	{
		path: WebUrl._URL_LOGIN,
		exact: true,
		title: "Login",
		authRequired: false,
		component: () => <Login />,
	},
	{
		path: WebUrl._URL_MAIN,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <Redirect to={WebUrl._URL_SHOP_SEARCH} />,
	},
	{
		path: WebUrl._URL_MAIN,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		feedbackAdminRedirect: () => <Redirect to={WebUrl._URL_MEMBER_FEEDBACK_REPORT} />,
		component: () => <Redirect to={WebUrl._URL_DASHBOARD} />,
	},
	{
		path: WebUrl._URL_DASHBOARD,
		exact: true,
		authRequired: true,
		title: "Dashboard",
		permission: AccessRight._DASHBOARD_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <Dashboard />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_VENDOR,
		exact: true,
		authRequired: true,
		title: "Manage Vendors",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageVendor />,
	},
	{
		path: WebUrl._URL_ADMIN_VENDOR_DETAIL,
		exact: true,
		authRequired: true,
		title: "Vendor Details",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <VendorDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_VENDOR_API,
		exact: true,
		authRequired: true,
		title: "Manage APIs",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageVendorApi />,
	},
	{
		path: WebUrl._URL_ADMIN_VENDOR_API_DETAIL,
		exact: true,
		authRequired: true,
		title: "API Key Details",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <VendorApiDetail />,
	},
	{
		path: WebUrl._URL_SYSTEM_SETTINGS,
		exact: true,
		authRequired: true,
		title: "System Settings",
		permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageSystemSettings />,
	},
	{
		path: WebUrl._URL_SMS_SETTINGS,
		exact: true,
		authRequired: true,
		title: "SMS Settings",
		permission: AccessRight._SMS_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageSmsSettings />,
	},
	{
		path: WebUrl._URL_SMS_DETAILS,
		exact: true,
		authRequired: true,
		title: "Sms Details",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <SmsDetails />,
	},
	{
		path: WebUrl._URL_SHOP_SEARCH,
		exact: true,
		authRequired: true,
		title: "Search",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <Search />,
	},
	{
		path: WebUrl._URL_SHOP_BLOCK_PUK,
		exact: true,
		authRequired: true,
		title: "Search",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <BlockPuk />,
	},
	{
		path: WebUrl._URL_SHOP_REGISTER_MEMBER,
		exact: true,
		authRequired: true,
		title: "Register Member",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <RegisterMember />,
	},
	{
		path: WebUrl._URL_SHOP_REGISTER_PLAYER,
		exact: true,
		authRequired: true,
		title: "Register Player",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <RegisterPlayer />,
	},
	{
		path: WebUrl._URL_SHOP_AGENT_COMMISSION_REPORT,
		exact: true,
		authRequired: true,
		title: "Commission Report",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <ShopAgentCommissionReport />,
	},
	{
		path: WebUrl._URL_SHOP_CREDIT_TRANSFER_REPORT,
		exact: true,
		authRequired: true,
		title: "Credit Transfer Report",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <ShopCreditTransferReport />,
	},
	{
		path: WebUrl._URL_SHOP_CREDIT_TOPUP_REPORT,
		exact: true,
		authRequired: true,
		title: "Credit Topup Report",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <ShopCreditTopupReport />,
	},
	{
		path: WebUrl._URL_SHOP_RELOAD,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <Reload />,
	},
	{
		path: WebUrl._URL_SHOP_EGHL_RELOAD,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <EGHLReload />,
	},
	{
		path: WebUrl._URL_SHOP_CLAIM_BONUS,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <ClaimBonus />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_ROLE,
		exact: true,
		authRequired: true,
		title: "Manage Role",
		permission: AccessRight._ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageRole />,
	},
	{
		path: WebUrl._URL_ADMIN_ROLE_DETAIL,
		exact: true,
		authRequired: true,
		title: "Role Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <RoleDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_ADMIN,
		exact: true,
		authRequired: true,
		title: "Manage Admin",
		permission: AccessRight._ADMIN_SUB_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageAdmin />,
	},
	{
		path: WebUrl._URL_ADMIN_ADMIN_DETAIL,
		exact: true,
		authRequired: true,
		title: "Admin Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AdminDetail />,
	},
	{
		path: WebUrl._URL_SHOP_MEMBER_DETAILS,
		exact: true,
		authRequired: true,
		title: "Member Details",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <MemberDetails />,
	},
	{
		path: WebUrl._URL_SHOP_REPORT,
		exact: true,
		authRequired: true,
		title: "Report",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <Report />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_SHOP,
		exact: true,
		authRequired: true,
		title: "Manage Shops",
		permission: AccessRight._SHOP_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageShop isCustomerService={false} />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_AGENT,
		exact: true,
		authRequired: true,
		title: "Manage Agents",
		permission: AccessRight._AGENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageAgent />,
	},
	{
		path: WebUrl._URL_ADMIN_CREATE_OR_UPDATE_AGENT,
		exact: true,
		authRequired: true,
		title: "Create Agents",
		permission: AccessRight._AGENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AgentDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_AGENT_VENDOR_API_DETAILS,
		exact: true,
		authRequired: true,
		title: "Agents API Details",
		permission: AccessRight._AGENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AgentVendorApiDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_CUSTOMER_SERVICE,
		exact: true,
		authRequired: true,
		title: "Manage Customer Service",
		permission: AccessRight._CUSTOMER_SERVICE_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageShop isCustomerService={true} />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_SHOP_API,
		exact: true,
		authRequired: true,
		title: "Manage Shops",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageShopApi />,
	},
	{
		path: WebUrl._URL_ADMIN_CREATE_OR_UPDATE_SHOP,
		exact: true,
		authRequired: true,
		title: "Create Shops",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ShopDetail isCustomerService={false} />,
	},
	{
		path: WebUrl._URL_ADMIN_CREATE_OR_UPDATE_CUSTOMER_SERVICE,
		exact: true,
		authRequired: true,
		title: "Create Customer Service",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ShopDetail isCustomerService={true} />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_SHOP_MESSAGE,
		exact: true,
		authRequired: true,
		title: "Manage Shop Message",
		permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageShopMessage />,
	},
	{
		path: WebUrl._URL_ADMIN_SHOP_MESSAGE_DETAIL,
		exact: true,
		authRequired: true,
		title: "Shop Message Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ShopMessageDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_MANAGER,
		exact: true,
		authRequired: true,
		title: "Manage Manager",
		permission: AccessRight._MANAGER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageManager />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGER_DETAIL,
		exact: true,
		authRequired: true,
		title: "Manger Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagerDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_SYSTEM_MESSAGE,
		exact: true,
		authRequired: true,
		title: "Manage Shop Message",
		permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageSystemMessage />,
	},
	{
		path: WebUrl._URL_ADMIN_SYSTEM_MESSAGE_DETAIL,
		exact: true,
		authRequired: true,
		title: "Shop Message Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <SystemMessageDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_PLAYER,
		exact: true,
		authRequired: true,
		title: "Manage Player",
		permission: AccessRight._PLAYER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePlayer />,
	},
	{
		path: WebUrl._URL_PROMOTION_MANAGE_PROMOTION,
		exact: true,
		authRequired: true,
		title: "Manage Bonus / Spin",
		permission: AccessRight._PROMOTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePromotion isSpinPromotion={false} />,
	},
	{
		path: WebUrl._URL_PROMOTION_MANAGE_PROMOTION_DETAIL,
		exact: true,
		authRequired: true,
		title: "Create Promotion",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePromotionDetail isSpinPromotion={false} />,
	},
	{
		path: WebUrl._URL_PROMOTION_MANAGE_SPIN_PROMOTION,
		exact: true,
		authRequired: true,
		title: "Manage Bonus / Spin",
		permission: AccessRight._PROMOTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePromotion isSpinPromotion={true} />,
	},
	{
		path: WebUrl._URL_PROMOTION_MANAGE_SPIN_PROMOTION_DETAIL,
		exact: true,
		authRequired: true,
		title: "Create Lucky Spin",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePromotionDetail isSpinPromotion={true} />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_PHONE_MESSAGE,
		exact: true,
		authRequired: true,
		title: "Manage Phone Message",
		permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePhoneMessage />,
	},
	{
		path: WebUrl._URL_ADMIN_PHONE_MESSAGE_DETAIL,
		exact: true,
		authRequired: true,
		title: "Phone Message Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <PhoneMessageDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_MEMBER,
		exact: true,
		authRequired: true,
		title: "Manage Member",
		permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageMember />,
	},
	{
		path: WebUrl._URL_ADMIN_CREATE_OR_UPDATE_MEMBER,
		exact: true,
		authRequired: true,
		title: "Member Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <MemberDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_OPERATION_LOG,
		exact: true,
		authRequired: true,
		title: "Operation Log",
		permission: AccessRight._OPERATIONAL_LOG_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <OperationLog />,
	},
	{
		path: WebUrl._URL_ADMIN_VIEW_ANNOUNCEMENT,
		exact: true,
		authRequired: true,
		title: "View Announcement",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ViewAnnouncement />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_MINI_PROGRAM,
		exact: true,
		authRequired: true,
		title: "Manage Mini Program",
		permission: AccessRight._MINI_PROGRAM_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageMiniProgram />,
	},
	{
		path: WebUrl._URL_ADMIN_MINI_PROGRAM_DETAIL,
		exact: true,
		authRequired: true,
		title: "Mini Program Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <MiniProgramDetail />,
	},
	{
		path: WebUrl._URL_SHOP_PLAYER_RELOAD,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <PlayerReload />,
	},
	{
		path: WebUrl._URL_SHOP_VIEW_ANNOUNCEMENT,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <Announcement />,
	},
	{
		path: WebUrl._URL_SET_LIMIT_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._SET_LIMIT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <SetLimitReport />,
	},
	{
		path: WebUrl._URL_SET_SCORE_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._SET_SCORE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <SetScoreReport />,
	},
	{
		path: WebUrl._URL_CLAIM_REWARD_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._SET_SCORE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ClaimRewardReport />,
	},
	{
		path: WebUrl._URL_MANAGER_LIMIT_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._MANAGER_LIMIT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagerLimitReport />,
	},
	{
		path: WebUrl._URL_SALES_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._SALES_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <SalesReport />,
	},
	{
		path: WebUrl._URL_REWARD_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._REWARD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <RewardReport />,
	},
	{
		path: WebUrl._URL_TURNOVER_REWARD_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._REWARD_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <TurnoverRewardReport />,
	},
	{
		path: WebUrl._URL_KIOSK_TIMEOUT_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._KIOSK_TIMEOUT_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <KioskTimeoutReport />,
	},
	{
		path: WebUrl._URL_AGENT_COMMISSION_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._AGENT_COMM_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AgentCommissionReport />,
	},
	{
		path: WebUrl._URL_AGENT_NETPROFIT_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._AGENT_NETPROFIT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AgentNetProfitReport />,
	},
	{
		path: WebUrl._URL_STRIKE_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._STRIKE_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <StrikeReport />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_MEMBER_PLAYER,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <MemberPlayerControl />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_PAYMENT_GATEWAY,
		exact: true,
		authRequired: true,
		permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePaymentGateway />,
	},
	{
		path: WebUrl._URL_ADMIN_CREATE_OR_UPDATE_PAYMENT_GATEWAY,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagePaymentGatewayDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_IP_BLACKLIST,
		exact: true,
		authRequired: true,
		permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageIPBlackList />,
	},
	{
		path: WebUrl._URL_TOPUP_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._TOP_UP_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <TopUpReport />,
	},
	{
		path: WebUrl._URL_CREDIT_TRANSFER_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._CREDIT_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <CreditTransferReport />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_SHOP_LOGIN,
		exact: true,
		authRequired: true,
		permission: AccessRight._SHOP_LOGIN_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageShopLogin />,
	},
	{
		path: WebUrl._URL_MANAGE_LUCKY_NUMBER,
		exact: true,
		authRequired: true,
		permission: AccessRight._LUCKY_NUMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageLuckyNumber />,
	},
	{
		path: WebUrl._URL_MANAGE_APPEAL_TRANSACTION,
		exact: true,
		authRequired: true,
		permission: AccessRight._APPEAL_TRANSACTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageAppealTransaction />,
	},
	{
		path: WebUrl._URL_MANAGE_ROLLOVER_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._ROLLOVER_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <RolloverReport />,
	},
	{
		path: WebUrl._URL_MANAGE_UPPER_LIMIT_WARNING_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._UPPER_LIMIT_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <UpperLimitWarningReport />,
	},
	{
		path: WebUrl._URL_SHOP_TRANSACTION_REQUEST,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <TransactionRequest />,
	},
	{
		path: WebUrl._URL_SHOP_LIMIT_APPROVAL_REPORT,
		exact: true,
		authRequired: true,
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <ShopLimitApprovalReport />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_VENDOR_CATEGORY,
		exact: true,
		authRequired: true,
		title: "Manage Vendor Category",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageVendorCategory />,
	},
	{
		path: WebUrl._URL_ADMIN_VENDOR_CATEGORY_DETAIL,
		exact: true,
		authRequired: true,
		title: "Vendors Category Details",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <VendorCategoryDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_RANK,
		exact: true,
		authRequired: true,
		title: "Manage Rank",
		permission: AccessRight._RANK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageRank />,
	},
	{
		path: WebUrl._URL_ADMIN_RANK_DETAIL,
		exact: true,
		authRequired: true,
		title: "Rank Detail",
		permission: AccessRight._RANK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <RankDetail />,
	},
	{
		path: WebUrl._URL_NOTIFICATION_SETTINGS,
		exact: true,
		authRequired: true,
		title: "Notification Settings",
		permission: AccessRight._SMS_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageNotificationSettings />,
	},
	{
		path: WebUrl._URL_NOTIFICATION_DETAILS,
		exact: true,
		authRequired: true,
		title: "Notification Details",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <NotificationDetails />,
	},
	{
		path: WebUrl._URL_USER_EXPERIENCE_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._OPERATIONAL_LOG_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <UserExperienceReport />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_MEMBER_DOWNLINE,
		exact: true,
		authRequired: true,
		title: "Manage Member Downline",
		permission: AccessRight._MEMBER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageMemberDownline />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_APP_ANNOUNCEMENT,
		exact: true,
		authRequired: true,
		title: "Manage App Annoucement",
		permission: AccessRight._ANNOUNCEMENT_EVENT_CONTROL + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageAppAnnoucement />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_APP_ANNOUNCEMENT_DETAIL,
		exact: true,
		authRequired: true,
		title: "App Annoucement Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AppAnnoucementDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_KOG_EVENT,
		exact: true,
		authRequired: true,
		title: "Manage KOG Event",
		permission: AccessRight._EVENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageKogEvent />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_KOG_EVENT_DETAIL,
		exact: true,
		authRequired: true,
		title: "KOG Event Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <KogEventDetail />,
	},
	{
		path: WebUrl._URL_ADMIN_KOG_EVENT_RANKING,
		exact: true,
		authRequired: true,
		title: "KOG Event Ranking",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <KogEventRanking />,
	},
	{
		path: WebUrl._URL_TURNOVER_REPORT,
		exact: true,
		authRequired: true,
		permission: AccessRight._TURNOVER_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <TurnoverReport />,
	},
	{
		path: WebUrl._URL_FEEDBACK_SETTINGS,
		exact: true,
		authRequired: true,
		title: "Manage Feedback Settings",
		permission: AccessRight._FEEDBACK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageFeedbackSettings />,
	},
	{
		path: WebUrl._URL_MEMBER_FEEDBACK_REPORT,
		exact: true,
		authRequired: true,
		title: "Member Feedback",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		permission: AccessRight._FEEDBACK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		component: () => <MemberFeedbackReport />,
	},
	{
		path: WebUrl._URL_SHOP_FEEDBACK_REPORT,
		exact: true,
		authRequired: true,
		title: "Shop Feedback",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		permission: AccessRight._FEEDBACK_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		component: () => <ShopFeedbackReport />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_BANNER,
		exact: true,
		authRequired: true,
		title: "Manage Banner",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		permission: AccessRight._BANNER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		component: () => <ManageBanner />,
	},
	{
		path: WebUrl._URL_ADMIN_MANAGE_BANNER_DETAIL,
		exact: true,
		authRequired: true,
		title: "Banner Detail",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		permission: AccessRight._BANNER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		component: () => <ManageBannerDetail />,
	},
	{
		path: WebUrl._URL_MEMBER_ECREDIT_LOG,
		exact: true,
		authRequired: true,
		permission: AccessRight._CREDIT_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <MemberEcreditTransferLog />,
	},
	{
		path: WebUrl._URL_REGION_ECREDIT_LOG,
		exact: true,
		authRequired: true,
		permission: AccessRight._CREDIT_TRANSFER_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <RegionEcreditTransferLog />,
	},
	{
		path: WebUrl._URL_VIEW_USER_ACTION_LOG,
		exact: true,
		authRequired: true,
		title: "View User Action Log",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ViewUserActionLog />,
	},
	{
		path: WebUrl._URL_VIEW_USER_TRANSACTION_LOG,
		exact: true,
		authRequired: true,
		title: "View User Transaction Log",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ViewTransactionLog />,
	},
	{
		path: WebUrl._URL_LUCKY_SPIN_WINNER,
		exact: true,
		authRequired: true,
		title: "Lucky Spin Winner",
		loginType: [LoginType._LOGIN_TYPE_ADMIN, LoginType._LOGIN_TYPE_SHOP],
		component: () => <LuckySpinWinner />,
	},
	{
		path: WebUrl._URL_KOG_WINNER,
		exact: true,
		authRequired: true,
		title: "KOG Winner",
		loginType: [LoginType._LOGIN_TYPE_ADMIN, LoginType._LOGIN_TYPE_SHOP],
		component: () => <KogWinner />,
	},
	{
		path: WebUrl._URL_ADMIN_OVERALL_DOWNLINE_REPORT,
		exact: true,
		authRequired: true,
		title: "Overall Downline Report",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <OverallDownlineReport />,
	},
	{
		path: WebUrl._URL_ADMIN_RANK_SUMMARY_REPORT,
		exact: true,
		authRequired: true,
		title: "Rank Summary Report",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <RankSummaryReport />,
	},
	{
		path: WebUrl._URL_API_LOG,
		exact: true,
		authRequired: true,
		title: "Api Log",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ApiLog />,
	},
	{
		path: WebUrl._URL_AGENT_WINLOSS_REPORT,
		exact: true,
		authRequired: true,
		title: "Agent Winloss Report",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AgentWinLossReport />,
	},
	{
		path: WebUrl._URL_AGENT_WINLOSS_REPORT_2,
		exact: true,
		authRequired: true,
		title: "Agent Winloss Report",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <AgentWinLossReport2 />,
	},
	{
		path: WebUrl._URL_LOTTO_SEAMLESS_SETTLEMENT_REPORT,
		exact: true,
		authRequired: true,
		title: "Lotto Seamless Settlement Report",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <LottoSeamlessSettlementReport />,
	},
	{
		path: WebUrl._URL_ADMIN_KOG_VIP_MEMBER,
		exact: true,
		authRequired: true,
		title: "Manage KOG VIP Member",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageVipMember />,
	},
	{
		path: WebUrl._URL_ONLINE_WITHDRAWAL_REQUEST,
		exact: true,
		authRequired: true,
		title: "Online Withdrawal Request",
		loginType: [LoginType._LOGIN_TYPE_ADMIN, LoginType._LOGIN_TYPE_SHOP],
		component: () => <OnlineWithdrawalRequest />,
	},
	{
		path: WebUrl._URL_MANAGER_LIMIT_TRANSACTION_REPORT,
		exact: true,
		authRequired: true,
		title: "Set Manager Limit",
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManagerLimitTransactionReport />,
	},
	{
		path: WebUrl._URL_SHOP_PLAYER_CREATION_REPORT,
		exact: true,
		authRequired: true,
		title: "Player Creation Report",
		loginType: [LoginType._LOGIN_TYPE_SHOP],
		component: () => <PlayerCreationReport />,
	},
	{
		path: WebUrl._URL_MANAGE_THIRD_PARTY,
		exact: true,
		authRequired: true,
		title: "Manage Third Party",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageThirdParty />,
	},
	{
		path: WebUrl._URL_THIRD_PARTY_DETAIL,
		exact: true,
		authRequired: true,
		title: "Third Party Detail",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ThirdPartyDetail />,
	},
	{
		path: WebUrl._URL_MANAGE_THIRD_PARTY_API,
		exact: true,
		authRequired: true,
		title: "Manage Third Party APIs",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageThirdPartyApi />
	},
	{
		path: WebUrl._URL_THIRD_PARTY_API_DETAIL,
		exact: true,
		authRequired: true,
		title: "Third Party API Detail",
		permission: AccessRight._GAME_VENDOR_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ThirdPartyApiDetail />
	},
	{
		path: WebUrl._URL_MANAGE_WELCOME_BONUS,
		exact: true,
		authRequired: true,
		title: "Manage Bonus",
		permission: AccessRight._PROMOTION_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ManageBonus />
	},
	{
		path: WebUrl._URL_USER_GROWTH_REPORT,
		exact: true,
		authRequired: true,
		title: "User Growth Report",
		permission: AccessRight._USER_GROWTH_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <UserGrowthReport />,
	},
	{
		path: WebUrl._URL_SHOP_EGHL_REPORT,
		exact: true,
		authRequired: true,
		title: "Shop EGHL Report",
		permission: AccessRight._SHOP_EGHL_REPORT_PERMISSION + AccessRight._READ_PERMISSION_STRING,
		loginType: [LoginType._LOGIN_TYPE_ADMIN],
		component: () => <ShopEghlReport />,
	},
];
