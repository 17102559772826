import React, { useState, useMemo, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { createMultiPartFormBody, stringIsNullOrEmpty } from '../../util/Util';
import { ApiUrl, ApiKey, Role, AlertTypes } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine';
import { useTranslation } from 'react-i18next';
import { showMessage, showResponseMessage } from "redux/AppAction";
import { useForm } from "react-hook-form";

/// <summary>
/// Author: YJ
/// </summary>
const SetShopEghlLimitModal = ({ visible, shopLimitDetails, onFinish }) => {
    var _userData = useSelector(state => state.authState.userData);
    const _dispatch = useDispatch();
    const { t } = useTranslation();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [currentLimit, setCurrentLimit] = useState();
    const _FIXED_LIMIT_OPTIONS = [
        { label: "500", value: 500 },
        { label: "1,000", value: 1000 },
        { label: "2,000", value: 2000 },
        { label: "3,000", value: 3000 },
        { label: "5,000", value: 5000 }
    ];

    /// <summary>
    /// Author: YJ
    /// get the latest credit
    /// </summary>
    useEffect(() => {
        if (visible) {
            refreshEghlLimit();
        }
    }, [visible])

    /// <summary>
    /// Author: YJ
    /// </summary>
    const refreshEghlLimit = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOP_EGHL_LIMIT + "?userId=" + shopLimitDetails.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setValue('currentEghlLimit', responseJson[ApiKey._API_DATA_KEY]);
            setCurrentLimit(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    const onSubmit = async (data) => {
        let params = {
            "amount": parseFloat(data.creditLimit),
            "userId": shopLimitDetails.id,
            "remark": data.billNumber
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_SET_SHOP_EGHL_LIMIT, params);

        _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        onFinish(true);
    }

    /// <summary>
    /// Author: YJ
    /// </summary>
    const renderFixedLimitBtns = useMemo(() => {
        return _FIXED_LIMIT_OPTIONS.map((option, index) => {
            let parsedNumber = parseFloat(option.value);
            let parsedCurrLimit = parseFloat(currentLimit);
            var valueToSet = 0;

            if (parsedCurrLimit > parsedNumber) {
                valueToSet = (parsedCurrLimit - parsedNumber) * -1;
            }
            else {
                valueToSet = parsedNumber - parsedCurrLimit;
            }

            return (
                <button type="button" className="btn btn-warning btn-action m-r-10 m-b-10" onClick={() => {
                    setValue('creditLimit', valueToSet);
                }}>{option.label}</button>
            );
        })
    }, [currentLimit, shopLimitDetails]);

    return (
        <Modal isOpen={visible} toggle={onFinish}>
            <ModalHeader>{t("SET_SHOP_EGHL_LIMIT_FOR")} {shopLimitDetails.userFullName}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group m-b-15 ">
                        <label>{t("CURRENT_LIMIT")}</label>
                        <div className="input-group">
                            <input
                                disabled
                                className="form-control form-control-lg m-b-5"
                                type="number"
                                name="currentEghlLimit"
                                defaultValue={currentLimit}
                                ref={register} />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-default no-caret" onClick={() => refreshEghlLimit()}><i className="fas fa-sync-alt"></i>{t("REFRESH")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-b-15 ">
                        <label>{t("CREDIT_LIMIT")}</label>
                        <input
                            placeholder={t("ENTER_CREDIT_LIMIT")}
                            className="form-control form-control-lg m-b-5"
                            name="creditLimit"
                            step={0.01}
                            type="number"
                            ref={register({ required: 'PLEASE_ENTER_CREDIT_LIMIT' })} />
                        {errors.creditLimit && <div className="invalid-feedback">{t(errors.creditLimit.message)}</div>}
                    </div>
                    <div className="form-group m-b-15 ">
                        <label>{t("BILL_NUMBER")}</label>
                        <input
                            placeholder={t("ENTER_BILL_NUMBER")}
                            className="form-control form-control-lg m-b-5"
                            type="text"
                            name="billNumber"
                            ref={register} />
                        {errors.billNumber && <div className="invalid-feedback">{t(errors.billNumber.message)}</div>}
                    </div>
                    <div>
                        {renderFixedLimitBtns}
                    </div>
                    <div className="pull-right">
                        <button type="button" className="btn btn-default btn-action m-r-10 m-b-10" onClick={() => onFinish()}>{t('CLOSE')}</button>
                        <button type="submit" className="btn btn-primary btn-action m-b-10">{t('SUBMIT')}</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default SetShopEghlLimitModal;