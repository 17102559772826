import React, { useRef, useEffect, useState } from 'react';
import { Panel, PanelBody } from 'components/panel/panel.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { showResponseMessage, showMessage } from 'redux/AppAction.js';
import { AlertTypes, ApiKey, FilterStatus, Status, TransactionTypeId, Role, TransactionRequestStatus, FirebaseKey, TransactionType } from 'util/Constant.js';
import { ApiUrl, WebUrl } from '../../util/Constant';
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, createFormBody, isObjectEmpty, printReceipt } from "../../util/Util";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocation, withRouter, useHistory } from "react-router-dom";
import { getShopCredit } from "../../redux/AuthAction.js";

/// <summary>
/// Author: CK
/// </summary>
const EghlServiceDetail = ({ subCategory }) => {
    const _ROUTE_KEY_PIN = "pin";
    const _ROUTE_KEY_ETU = "etu";
    const _ROUTE_KEY_PMT = "pmt";
    const _INPUT_TYPE_PHONE = 'phone_no';
    const _INPUT_TYPE_ACCOUNT = 'acc_no';

    const { register, handleSubmit, errors, triggerValidation, setValue, getValues, watch } = useForm();
    var _dispatch = useDispatch();
    const { t } = useTranslation();
    var _userData = useSelector(state => state.authState.userData);
    var _eghlLimit = useSelector(state => state.authState.eghlLimit);
    const [serviceDetail, setServiceDetail] = useState();
    const [selectedServiceType, setSelectedServiceType] = useState();
    const [selectedService, setSelectedService] = useState();

    var _requiredInputField = selectedService ? selectedService.max_denom != selectedService.min_denom : false;

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        geteGHLServiceDetail();
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (selectedServiceType == _ROUTE_KEY_PIN && !isObjectEmpty(serviceDetail.pin)) {
            setSelectedService(serviceDetail.pin.services[0]);
        }
        else if (selectedServiceType == _ROUTE_KEY_ETU && !isObjectEmpty(serviceDetail.etu)) {
            setSelectedService(serviceDetail.etu.services[0]);
        }
        else if (selectedServiceType == _ROUTE_KEY_PMT && !isObjectEmpty(serviceDetail.pmt)) {
            setSelectedService(serviceDetail.pmt.services[0]);
        }
    }, [selectedServiceType]);

    /// <summary>
    /// Author: CK
    /// </summary>
    const geteGHLServiceDetail = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SERVICE_DETAIL + "?subCategoryId=" + subCategory.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let currentServiceDetail = responseJson[ApiKey._API_DATA_KEY];
            setServiceDetail(currentServiceDetail);

            if (currentServiceDetail.selection.length > 0) {
                let currentSelectedServiceType = currentServiceDetail.selection[0];
                setSelectedServiceType(currentSelectedServiceType);
            }
        }
        else {
            _dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const submitForm = async (data, e) => {
        console.log("Data", data, selectedService);
        if (_requiredInputField && !selectedService) {
            _dispatch(showResponseMessage(false, t("PLEASE_SELECT_AMOUNT")));
            return;
        }

        let params = {};
        let dialogConfirmationService = "";

        if (selectedServiceType == _ROUTE_KEY_PIN) {
            if (selectedService.max_denom > _eghlLimit) {
                _dispatch(showResponseMessage(false, t("INSUFFICIENT_LIMIT")));
                return;
            }

            dialogConfirmationService = "Amount: RM" + selectedService.max_denom;

            params = {
                'initialAmount': selectedService.max_amount_cents,
                'amount': selectedService.max_amount_cents,
                'serviceId': selectedService.id,
                'type': selectedServiceType.toUpperCase(),
                'deviceId': window.navigator.userAgent,
                'enableVerification': false
            };
        }
        else if (selectedServiceType == _ROUTE_KEY_ETU) {
            if ((_requiredInputField ? data['amount'] : selectedService.min_denom) > _eghlLimit) {
                _dispatch(showResponseMessage(false, t("INSUFFICIENT_LIMIT")));
                return;
            }

            dialogConfirmationService = !stringIsNullOrEmpty(data['accountNo']) ? "Account No: " + data['accountNo'] + "\n" : "";
            dialogConfirmationService += "Amount: RM" + (_requiredInputField ? data['amount'] : selectedService.min_denom);

            params = {
                'amount': (_requiredInputField ? data['amount'] : selectedService.min_denom) * 100,
                'serviceId': selectedService.id,
                'type': selectedServiceType.toUpperCase(),
                'deviceId': window.navigator.userAgent,
                'enableVerification': false,
                'accountNo': data['accountNo']
            };
        }
        else if (selectedServiceType == _ROUTE_KEY_PMT) {
            if (data['amount'] > _eghlLimit) {
                _dispatch(showResponseMessage(false, t("INSUFFICIENT_LIMIT")));
                return;
            }

            dialogConfirmationService = !stringIsNullOrEmpty(data['accountNo']) ? "Account No: " + data['accountNo'] + "\n" : "";
            dialogConfirmationService += "Amount: RM" + (_requiredInputField ? data['amount'] : selectedService.min_denom);

            params = {
                'amount': data['amount'] * 100,
                'serviceId': selectedService.id,
                'type': selectedServiceType.toUpperCase(),
                'deviceId': window.navigator.userAgent,
                'enableVerification': false,
                'accountNo': data['accountNo']
            };
        }

        if (params.amount <= 0) {
            _dispatch(showResponseMessage(false, t("MIN_ERROR")));
            return;
        }

        _dispatch(showMessage({
            type: AlertTypes._INFO,
            title: "Confirm purchase " + subCategory.ssc_name,
            content: dialogConfirmationService,
            showCancel: true,
            onConfirm: () => {
                createEghlTransaction(params);
            }
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const createEghlTransaction = async (params) => {
        console.log(params);
        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_EGHL_TRANSACTION, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let eGHLResponse = responseJson[ApiKey._API_DATA_KEY];
            let receiptContent = !stringIsNullOrEmpty(eGHLResponse.pin) ? "Pin: " + eGHLResponse.pin + "\n" : "";
            receiptContent += !stringIsNullOrEmpty(eGHLResponse.pin_expiry) ? "Pin Expiry: " + eGHLResponse.pin_expiry + "\n" : "";
            receiptContent += !stringIsNullOrEmpty(eGHLResponse.details) ? "Instruction: " + eGHLResponse.details : "";

            _dispatch(showMessage({
                type: AlertTypes._SUCCESS,
                title: t("SUCCESS"),
                content: "Transaction ID: " + eGHLResponse.transactionId + "\nPlease print the receipt for pin/instruction to topup.\n" + "",
                confirmBtnText: t("PRINT"),
                confirmBtnBsStyle: "success",
                showCancel: false,
                cancelBtnText: "OK",
                cancelBtnBsStyle: "primary",
                onConfirm: () => {
                    printReceipt(receiptContent);
                }
            }));
    
            _dispatch(getShopCredit());
        }
        else {
            _dispatch(showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY]));
        }
    }

    return <div className="container">
        {
            subCategory &&
            <div className="service-header">
                <img src={subCategory.img} alt={subCategory.ssc_name} />
                <h1>{subCategory.ssc_name}</h1>
            </div>
        }
        <div className="row" style={{ justifyContent: 'center' }}>
            {
                serviceDetail ?
                    <div>
                        <div className="service-tab">
                            {
                                serviceDetail.selection.map(i => {
                                    return <button
                                        type="button"
                                        class={`btn btn-white ${selectedServiceType == i ? "active" : ""}`}
                                        onClick={() => { setSelectedServiceType(i); }}
                                    >
                                        {t(i.toUpperCase())}
                                    </button>
                                })
                            }
                        </div>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <div className="service-tab-content">
                                {
                                    selectedServiceType == _ROUTE_KEY_PIN && !isObjectEmpty(serviceDetail.pin) ?
                                        <div className="service-amount-list">
                                            {
                                                serviceDetail.pin.services.map(i => {
                                                    return <button
                                                        type="button"
                                                        class={`btn btn-white ${selectedService?.id == i.id ? "active" : ""}`}
                                                        onClick={() => { setSelectedService(i); }}
                                                    >
                                                        {'RM ' + i.max_denom}
                                                    </button>
                                                })
                                            }
                                        </div>
                                        :
                                        selectedServiceType == _ROUTE_KEY_ETU && !isObjectEmpty(serviceDetail.etu) ?
                                            <div className="service-amount-list">
                                                <div className="form-group">
                                                    <label>{selectedService?.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}</label>
                                                    <input
                                                        value={watch('accountNo')}
                                                        className="form-control form-control-lg"
                                                        id="accountNo"
                                                        name="accountNo"
                                                        type="text"
                                                        placeholder={selectedService?.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}
                                                        ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
                                                    {errors.accountNo && <div className="invalid-feedback">{t(errors.accountNo.message)}</div>}
                                                </div>
                                                {
                                                    _requiredInputField ?
                                                        <div className="form-group ">
                                                            <label>{t("AMOUNT")}</label>
                                                            <input
                                                                placeholder={t("AMOUNT")}
                                                                className="form-control form-control-lg"
                                                                name="amount"
                                                                step={0.01}
                                                                type="number"
                                                                ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
                                                            {errors.amount && <div className="invalid-feedback">{t(errors.amount.message)}</div>}
                                                        </div>
                                                        :
                                                        serviceDetail.etu.services.map(i => {
                                                            return <button
                                                                type="button"
                                                                class={`btn btn-white ${selectedService?.id == i.id ? "active" : ""}`}
                                                                onClick={() => { setSelectedService(i); }}
                                                            >
                                                                {'RM ' + i.max_denom}
                                                            </button>
                                                        })
                                                }
                                            </div>
                                            :
                                            selectedServiceType == _ROUTE_KEY_PMT && !isObjectEmpty(serviceDetail.pmt) ?
                                                <div className="service-amount-list">
                                                    <div className="form-group">
                                                        <label>{selectedService?.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}</label>
                                                        <input
                                                            value={watch('accountNo')}
                                                            className="form-control form-control-lg"
                                                            id="accountNo"
                                                            name="accountNo"
                                                            type="text"
                                                            placeholder={selectedService?.input_type == _INPUT_TYPE_PHONE ? t('PHONE_NUMBER') : t('ACCOUNT_NUMBER')}
                                                            ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
                                                        {errors.accountNo && <div className="invalid-feedback">{t(errors.accountNo.message)}</div>}
                                                    </div>
                                                    {
                                                        _requiredInputField ?
                                                            <div className="form-group ">
                                                                <label>{t("AMOUNT")}</label>
                                                                <input
                                                                    placeholder={t("AMOUNT")}
                                                                    className="form-control form-control-lg"
                                                                    name="amount"
                                                                    step={0.01}
                                                                    type="number"
                                                                    ref={register({ required: "INPUT_FIELD_REQUIRED" })} />
                                                                {errors.amount && <div className="invalid-feedback">{t(errors.amount.message)}</div>}
                                                            </div>
                                                            :
                                                            serviceDetail.pmt.services.map(i => {
                                                                return <button
                                                                    type="button"
                                                                    class={`btn btn-white ${selectedService?.id == i.id ? "active" : ""}`}
                                                                    onClick={() => { setSelectedService(i); }}
                                                                >
                                                                    {'RM ' + i.max_denom}
                                                                </button>
                                                            })

                                                    }
                                                </div>
                                                :
                                                <></>
                                }
                                <button type="submit" class="btn btn-lime">{t("BUY")}</button>
                            </div>
                        </form>
                    </div>
                    :
                    <p className='text-center'>{t("NO_DATA_FOUND")}</p>
            }
        </div>
    </div>;
}

export default EghlServiceDetail;