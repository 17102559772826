import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import { Panel, PanelBody } from "../../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { Toast, ToastBody } from 'reactstrap';
import ReactTable from "../../../components/custom/ReactTable";
import { setBusy, setIdle, showResponseMessage } from '../../../redux/AppAction';
import { ApiKey, ApiUrl, Role, Status, AlertTypes, ShopEghlTransactionType, ShopEghlTransactionStatus } from "../../../util/Constant";
import { stringIsNullOrEmpty, arrayGroupByKey } from "../../../util/Util";
import { useForm } from "react-hook-form";
import ApiEngine from '../../../util/ApiEngine.js';
import { useDispatch, useSelector } from "react-redux";
import RegionFilterDualListBox from "../../../components/custom/RegionFilterDualListBox";

/// <summary>
/// Author : CK
/// </summary>
const ShopEghlReport = props => {
    let _dispatch = useDispatch();
    let { search } = useLocation();
    const _ALL_OPTION = -1;
    const _query = new URLSearchParams(search);
    const [postData, setPostData] = useState({});
    const { register, handleSubmit, setValue } = useForm();
    const { t } = useTranslation();
    const [apiUrl, setApiUrl] = useState('');
    const [shopOption, setShopOption] = useState([]);
    const [selectedShop, setSelectedShop] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [regions, setRegions] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const _userData = useSelector(state => state.authState.userData);

    let _tableColumns = [
        {
            Header: "No",
            id: "row",
            filterable: false,
            Cell: ({ row }) => {
                return <div>{row.index + 1}</div>;
            },
            disableSortBy: true
        },
        {
            Header: "DATE",
            accessor: "createdDateString",
            Footer: () => {
                return <span style={{ float: 'right' }}><b>TOTAL: </b></span>
            },
            disableSortBy: true,
        },
        {
            Header: "TRANSACTION_ID",
            accessor: "transactionId",
            Cell: ({ row }) => {
                if (!stringIsNullOrEmpty(row.original.transactionId)) {
                    return (
                        <>
                            <span>{row.original.transactionId}</span>
                            &nbsp;
                            <button
                                className='btn btn-primary btn-icon btn-circle btn-sm'
                                style={{
                                    'padding': '0',
                                    'borderRadius': '50px'
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(row.original.transactionId);
                                    setShowToast(!showToast);
                                }}
                            >
                                <i class="fas fa-copy"></i>
                            </button>
                            {
                                (_userData?.isAdminLevel || _userData?.isAbleViewLastMessage) && !stringIsNullOrEmpty(row.original.reloadPin) &&
                                <button
                                    className='btn btn-primary btn-icon btn-circle btn-sm'
                                    style={{
                                        'padding': '0',
                                        'borderRadius': '50px',
                                        'marginLeft': '7.5px'
                                    }}
                                    onClick={() => {
                                        _dispatch(showResponseMessage(true, row.original.reloadPin, null, false));
                                    }}
                                >
                                    <i class="fas fa-eye"></i>
                                </button>
                            }
                        </>
                    );
                }
                else {
                    return <>-</>
                }
            },
            disableSortBy: true,
        },
        {
            Header: "OPERATED_BY",
            accessor: "operatorUsername",
            Cell: ({ value }) => {
                return stringIsNullOrEmpty(value) ? "-" : value;
            },
            disableSortBy: true,
        },
        {
            Header: "SHOP",
            accessor: "username",
            disableSortBy: true
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return renderTransactionStatus(row.original.status);
            },
            disableSortBy: true
        },
        {
            Header: "TYPE",
            accessor: "typeId",
            Cell: ({ row }) => {
                return renderTransactionType(row.original.typeId);
            },
            disableSortBy: true
        },
        {
            Header: "INITIAL_LIMIT",
            accessor: "initialLimit",
            Cell: ({ row }) => {
                return row.original.initialLimit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: "EGHL_LIMIT",
            accessor: "amount",
            Cell: ({ row }) => {
                return row.original.amount > 0 ? <span className="text-success">{row.original.amount.toFixed(2)}</span> : <span className="text-danger">{row.original.amount.toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const scoreTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.amount);
                }, 0);

                return <span><b>{scoreTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "PROCESSING_FEE",
            accessor: "processingFee",
            Cell: ({ row }) => {
                return row.original.processingFee > 0 ? <span className="text-success">{row.original.processingFee.toFixed(2)}</span> : <span className="text-danger">{row.original.processingFee.toFixed(2)}</span>;
            },
            Footer: ({ page }) => {
                const scoreTotal = page.reduce((sum, currentValue) => {
                    return sum + parseFloat(currentValue.original.processingFee);
                }, 0);

                return <span><b>{scoreTotal.toFixed(2)}</b></span>
            },
            disableSortBy: true
        },
        {
            Header: "FINAL_LIMIT",
            accessor: "finalLimit",
            Cell: ({ row }) => {
                return row.original.finalLimit.toFixed(2)
            },
            disableSortBy: true
        },
        {
            Header: "MANAGER_INITIAL_LIMIT",
            accessor: "initialUsedLimit",
            Cell: ({ value }) => {
                return !stringIsNullOrEmpty(value) ? value.toFixed(2) : "-";
            },
            disableSortBy: true
        },
        {
            Header: "MANAGER_FINAL_LIMIT",
            accessor: "finalUsedLimit",
            Cell: ({ value }) => {
                return !stringIsNullOrEmpty(value) ? value.toFixed(2) : "-";
            },
            disableSortBy: true
        },
        {
            Header: "REMARK",
            accessor: "remark",
            disableSortBy: true,
            style: {
                width: 450,
                whiteSpace: 'break-spaces'
            },
            Cell: ({ value }) => {
                return stringIsNullOrEmpty(value) ? "-" : value;
            },
            minWidth: 450
        },
        {
            Header: "PROCESSED_DATE",
            accessor: "processedDateString",
            Cell: ({ value }) => {
                return stringIsNullOrEmpty(value) ? "-" : value;
            },
            disableSortBy: true,
        },
    ]

    /// <summary>
    /// Author : YJ
    /// </summary>
    const renderTransactionStatus = (status) => {
        let returnComponent = <></>

        if (status == ShopEghlTransactionStatus._PENDING) {
            returnComponent = <span className="badge badge-primary">{t("PENDING")}</span>;
        }
        else if (status == ShopEghlTransactionStatus._SUCCESS) {
            returnComponent = <span className="badge badge-green">{t("SUCCESS")}</span>;
        }
        else {
            returnComponent = <span className="badge badge-danger">{t("FAILED")}</span>;
        }

        return returnComponent;
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const renderTransactionType = (type) => {
        let transactionTypeString = "";

        switch (type) {
            case ShopEghlTransactionType._SET:
                transactionTypeString = t("SET");
                break;
            case ShopEghlTransactionType._USED:
                transactionTypeString = t("PURCHASE");
                break;
        }

        return <span className="badge badge-primary">{transactionTypeString}</span>
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        if (showToast) {
            setTimeout(() => setShowToast(!showToast), 3000)
        }
    }, [showToast])

    /// <summary>
    /// Author : YJ
    /// </summary>
    useEffect(() => {
        (async () => {
            register({ name: 'shopIds' });
            register({ name: 'startDate' }, { required: true });
            register({ name: 'endDate' }, { required: true });

            setValue('shopIds', []);
            setValue('startDate', moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
            setValue('endDate', moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
            await getShop();
        })();
    }, []);

    /// <summary>
    /// Author : YJ
    /// </summary>
    async function getShop() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_SHOPS + "?RoleId=" + Role._SHOP);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var shopListByRegion = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "regionName");
            var shopOptionsByRegion = [];
            var regionOptions = [];

            for (var key in shopListByRegion) {
                var shopList = [];

                regionOptions.push({ label: key, value: key });

                shopListByRegion[key].map(shop => {
                    shopList.push({ label: shop.username + (shop.shopName ? " (" + shop.shopName + ")" : ""), value: shop.id });
                });

                shopOptionsByRegion.push({
                    label: key,
                    value: key,
                    options: shopList
                })
            }

            setShopOption(shopOptionsByRegion);
            setRegions(regionOptions);
        }
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "TargetShop": data.shopIds,
        };

        if (data.status != _ALL_OPTION) {
            params["status"] = parseInt(data.status);
        }

        if (data.transactionType != _ALL_OPTION) {
            params["typeId"] = parseInt(data.transactionType);
        }

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_SHOP_EGHL_REPORT);
    }

    /// <summary>
    /// Author : YJ
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            columns={_tableColumns}
            fetchUrl={apiUrl}
            postData={postData}
            renderFooter={true}
            exportRequired={true} />
        , [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header d-flex justify-content-between">
                {t("SHOP_EGHL_REPORT")}
            </h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>{t("SHOP")}</label>
                                    <RegionFilterDualListBox
                                        canFilter
                                        name={"shopIds"}
                                        ref={register}
                                        options={shopOption}
                                        selectedOptions={selectedShop}
                                        regions={regions}
                                        onChange={(e) => {
                                            setValue('shopIds', e);
                                            setSelectedShop(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("STATUS")}</label>
                                    <select name="status" ref={register} className="form-control form-control-lg">
                                        <option value={_ALL_OPTION}>{t("ALL")}</option>
                                        <option value={ShopEghlTransactionStatus._PENDING}>{t("PENDING")}</option>
                                        <option value={ShopEghlTransactionStatus._SUCCESS}>{t("SUCCESS")}</option>
                                        <option value={ShopEghlTransactionStatus._FAILED}>{t("FAILED")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("TRANSACTION_TYPE")}</label>
                                    <select name="transactionType" ref={register} className="form-control form-control-lg">
                                        <option value={_ALL_OPTION}>{t("ALL")}</option>
                                        <option value={ShopEghlTransactionType._SET}>{t("SET")}</option>
                                        <option value={ShopEghlTransactionType._USED}>{t("PURCHASE")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="startDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control form-control-lg bg-white', readOnly: true }}
                                        name="endDate"
                                        ref={register}
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <Toast style={{ position: 'fixed', bottom: '1rem', left: '50%' }} isOpen={showToast} transition={{ key: "1", transitionLeaveTimeout: 3000 }}>
                <ToastBody>
                    {t('COPIED')}
                </ToastBody>
            </Toast>
        </div>
    );
};

export default ShopEghlReport;