import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { performLogout } from "../../../redux/AuthAction.js";
import { connect, useDispatch, useSelector } from "react-redux";
import ResetPasswordModal from '../../../components/custom/ResetPasswordModal';
import { useHistory } from 'react-router-dom';
import { navigateTo } from '../../../util/Util';
import { WebUrl, Role } from '../../../util/Constant';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const DropdownProfile = props => {
    const { t, i18n } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [changePasswordModalVisibility, setChangePasswordModalVisibility] = useState(false);
    const _userData = useSelector(state => state.authState.userData);
    const _dispatch = useDispatch();
    const _history = useHistory();

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user" tag="li">
                <DropdownToggle tag="a">
                    <span className="d-md-inline">{_userData.username}</span> <b className="caret"></b>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                    <DropdownItem onClick={() => { navigateTo(_history, _userData.userRoleId != Role._SHOP ? WebUrl._URL_ADMIN_VIEW_ANNOUNCEMENT : WebUrl._URL_SHOP_VIEW_ANNOUNCEMENT) }}>{t("VIEW_ANNOUNCEMENT")}</DropdownItem>
                    {_userData.userRoleId != Role._SHOP &&
                        <DropdownItem onClick={() => {
                            setChangePasswordModalVisibility(!changePasswordModalVisibility)
                        }}>{t('CHANGE_PASSWORD')}</DropdownItem>
                    }
                    {/* {
                        _userData.userRoleId == Role._SHOP && _userData.canEghlReload &&
                        <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_SHOP_EGHL_RELOAD) }}>eGHL Reload</DropdownItem>
                    } */}
                    <DropdownItem onClick={() => _dispatch(performLogout())}>{t('LOGOUT')}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <ResetPasswordModal
                onFinish={(success) => {
                    setChangePasswordModalVisibility(false);
                }}
                visible={changePasswordModalVisibility} />
        </>
    );
}

export default withRouter(DropdownProfile);
